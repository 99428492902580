import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Breadcrumbs items={[{
      name: 'Announcements',
      to: '/whats-new/announcements'
    }, {
      name: 'Canvas v9 Announcements'
    }]} mdxType="Breadcrumbs" />
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "september-2023-design-asset-release"
      }}>{`September 2023 Design Asset Release`}</h2>
      <p>{`The September 2023 Design Asset Release is now available for use through the Canvas Assets Figma
library for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`The following assets were updated as part of this release:`}</p>
      <ul>
        <li parentName="ul">{`Third Party Icons (upgrade `}<inlineCode parentName="li">{`@workday/third-party-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`1.0.9`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-twitter-x.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`twitterXIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) is now available for use`}</li>
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-glassdoor-2.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`glassdoor2Icon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) is now available for use`}</li>
          </ul>
        </li>
      </ul>
      <p>{`These third party icons will be released as net new icons. The existing `}<inlineCode parentName="p">{`twitterIcon`}</inlineCode>{` and
`}<inlineCode parentName="p">{`glassdoorIcon`}</inlineCode>{` icons will continue to exist, but teams may begin utilizing the updated version of
those icons with this release.`}</p>
      <p>{`This release will not impact developers, and designers can upgrade to the latest version of the
Canvas Asset Figma library without any impact to their existing designs.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
      <h2 {...{
        "id": "july-2023-design-asset-release"
      }}>{`July 2023 Design Asset Release`}</h2>
      <p>{`The July 2023 Design Asset Release is now available for use through the Canvas Assets Figma library.`}</p>
      <p>{`This month's design asset release will be Figma specific to catch up on icon updates from previous releases, which includes:`}</p>
      <ul>
        <li parentName="ul">{`An updated `}<inlineCode parentName="li">{`workdayCircleIcon`}</inlineCode>{` third party icon with the correct dub and sunrise element`}</li>
      </ul>
      <p>{`Please accept these changes in Figma to get access to the updated icons.`}</p>
      <p>{`For more information, please reference the `}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-910-release"
    }}>{`Canvas Kit 9.1.0 Release`}</h2>
    <p>{`Canvas Kit 9.1.0 is now available!`}</p>
    <p><strong parentName="p">{`Radio Compound Component`}</strong>{`
The Radio compound component is finally here! Teams using Radio in Main should start switching over to the new Radio compound component in Preview, which will give teams more control over individual sub-components within Radio.`}</p>
    <p><strong parentName="p">{`CSS Scroll Attributes for Box, Flex, & Grid`}</strong>{`
New CSS scroll attributes have been added to Box, Flex, and Grid to enable product teams to configure Layout components with CSS.`}</p>
    <p><strong parentName="p">{`Status Indicator Deprecation`}</strong>{`
The Status Indicator component in Main has been `}<a parentName="p" {...{
        "href": "/help/glossary#deprecation"
      }}>{`deprecated`}</a>{`. Teams using the Status Indicator in Main should switch over to the updated Status Indicator component in the Preview package, which has been updated to improve globalization and accessibility! The Status Indicator in Main is still available for use, but it will be removed in v11 (April 2024).`}</p>
    <p>{`This update will not impact developers. To access these changes, please upgrade to Canvas Kit 9.1.0.`}</p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "june-2023-design-asset-release"
      }}>{`June 2023 Design Asset Release`}</h2>
      <p>{`The April 2023 Design Asset Release is now available for use through the Canvas Assets Figma library
for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`This release contains the following updates:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`System Icons (upgrade `}<inlineCode parentName="p">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="p">{`3.0.16`}</inlineCode>{`)`}</p>
          <ul parentName="li">
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-messaging.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`messagingIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-lightbulb.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`lightbulbIcon`}</inlineCode>{`)`}</li>
            <li parentName="ul">{`A fix to the `}<inlineCode parentName="li">{`wd-icon-exclamation-triangle.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`exclamationTriangleIcon`}</inlineCode>{`) to remove a
small white outline that appears around the inner triangle when the icon is being filled`}</li>
          </ul>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Third Party Icons (upgrade `}<inlineCode parentName="p">{`@workday/third-party-icons-web`}</inlineCode>{` to `}<inlineCode parentName="p">{`1.0.8`}</inlineCode>{`)`}</p>
          <ul parentName="li">
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-android.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`androidIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-apple.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`appleIcon`}</inlineCode>{`)`}</li>
            <li parentName="ul">{`A new `}<inlineCode parentName="li">{`wd-icon-react.svg`}</inlineCode>{` icon (`}<inlineCode parentName="li">{`reactIcon`}</inlineCode>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include this update. This release will not
impact developers and designers can upgrade to the latest version of the Canvas Asset Figma library
without any impact to their existing designs.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h2 {...{
        "id": "canvas-kit-v9-release"
      }}>{`Canvas Kit v9 Release`}</h2>
      <p>{`Canvas Kit v9 will be released on `}<strong parentName="p">{`May 10th, 2023`}</strong>{`. An upgrade guide will be available once v8
goes live to assist product teams with their migration to the newest version of Canvas Kit.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Design System Site Versioning`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Versioning will be available for documentation on the Canvas Design System site, corresponding to each major Canvas release`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth Token Modifications`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth tokens will be modified to reduce the harshness/contrast of depth against backgrounds`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Table Refactoring`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Table component will be released in the Preview package with increased flexibility and updated styling`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Combobox Sub-system`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Combobox sub-system will be released to help developers build components and features with Combobox like autocomplete and Select`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Layout Component Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Layout component will be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Drawer Component Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Drawer component in the Labs package will be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Stack Component Removals`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Stack, VStack, HStack components will all be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default Button Type Modifications`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The default button type for all Canvas buttons will be updated from `}<inlineCode parentName="td">{`submit`}</inlineCode>{` to `}<inlineCode parentName="td">{`button`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useTheme`}</inlineCode>{` Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A fix will be implemented to prevent `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` hook from generating an error when the styled function is used without a predefined theme`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The `}<inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` hook will be promoted to the Main package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The `}<inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` hook will be deprecated in Canvas v9, but will still be available for use`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` will be removed in favor of `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getTheme`}</inlineCode>{`, respectively`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Kit’s TS Compiler Target Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The TS compiler target will be updated from ES5 to ES2019. This will significantly decrease the Canvas Kit bundle size`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Toast Component Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Toast component in the Labs package will be promoted to the Main package, replacing the current Toast component in Main`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Enhanced QA & Testing Processes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Additional tests will be added to expand testing across different screen sizes and modality as well as expand visual regression testing`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-v9-release"
      }}>{`Canvas v9 Release`}</h2>
      <p>{`Canvas v9 will be released on `}<strong parentName="p">{`May 10th, 2023`}</strong>{` with updates to Canvas Kit and the Canvas Figma
libraries. An upgrade guide will be available once v9 goes live to assist product teams with
upgrading to the newest version of Canvas.`}</p>
      <h3 {...{
        "id": "release-overview"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v9 release. The impact for designers and developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9 and/or switch to the new
v9 Canvas Web Figma library`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Web Figma Library Versioning`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Versioning will be available for the Canvas Web Figma library. A new Canvas Web Figma library will be created for each major Canvas release`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Design System Site Versioning`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Versioning will be available for documentation on the Canvas Design System site, corresponding to each major Canvas release`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth Token Modifications`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth tokens will be modified to reduce the harshness/contrast of depth against backgrounds`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Table Refactoring`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Table component will be released in the Preview package with increased flexibility and updated styling`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Combobox Sub-system`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Combobox sub-system will be released to help developers build components and features with Combobox like autocomplete and select`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Modal Figma Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Modal component will be updated in Figma to include an overlay and align with the expected behavior in Canvas Kit`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Figma Dialog Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Dialog component will be added to Canvas Web`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Segmented Control Figma Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Segmented Control component in the Canvas Web Figma library will be updated to reflect the version available in the Preview package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Status Indicator Figma Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Status Indicator component in the Canvas Web Figma library will be updated to reflect the version available in the Preview package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Switch Refactoring and Consolidation Figma Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Switch component will be refactored and consolidated into a single Switch component with variants`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Radio Variant Figma Consolidation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Radio component will be updated in the Canvas Web Figma library to consolidate the number of variants available`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Checkbox Variant Figma Consolidation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Checkbox component will be updated in the Canvas Web Figma library to consolidate the number of variants available`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Delete Button Figma Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated to be structurally consistent to other Buttons (Primary, Secondary, and Tertiary)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Layout Component Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Layout component will be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Drawer Component Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Drawer component in the Labs package will be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Stack Component Removals`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Stack, VStack, HStack components will all be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default Button Type Modifications`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The default button type for all Canvas buttons will be updated from `}<inlineCode parentName="td">{`submit`}</inlineCode>{` to `}<inlineCode parentName="td">{`button`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useTheme`}</inlineCode>{` Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A fix will be implemented to prevent `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` hook from generating an error when the styled function is used without a predefined theme`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The `}<inlineCode parentName="td">{`useThemedRing`}</inlineCode>{` hook will be promoted to the Main package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The `}<inlineCode parentName="td">{`useThemeRTL`}</inlineCode>{` hook will be deprecated in Canvas v9, but will still be available for use`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` Removal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getCanvasTheme`}</inlineCode>{` will removed in favor of `}<inlineCode parentName="td">{`useTheme`}</inlineCode>{` and `}<inlineCode parentName="td">{`getTheme`}</inlineCode>{`, respectively`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Kit’s TS Compiler Target Updates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The TS compiler target will be updated from ES5 to ES2019. This will significantly decrease the Canvas Kit bundle size`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Toast Component Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Toast component in the Labs package will be promoted to the Main package, replacing the current Toast component in Main`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Enhanced QA & Testing Processes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Additional tests will be added to expand testing across different screen sizes and modality as well as expand visual regression testing`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "canvas-web-figma-library-versioning"
      }}>{`Canvas Web Figma Library Versioning`}</h3>
      <p>{`Versioning will be available in the Canvas Web Figma Library! This will give anyone using Figma the
ability to stay on an older version, or upgrade to the latest version of the Canvas Web Library.
Most importantly, this will enable product teams to design, develop, and build using the same
version of Canvas. This Canvas Web v9 Figma library versioning update will include:`}</p>
      <ul>
        <li parentName="ul">{`Automated version upgrade process in Figma`}</li>
        <li parentName="ul">{`Improved find and search capabilities in Figma`}</li>
        <li parentName="ul">{`Updated file structure for component organization`}</li>
        <li parentName="ul">{`Prototype interaction support for all applicable Figma components`}</li>
        <li parentName="ul">{`Canvas documentation direct in Figma through Gist plug-in`}</li>
      </ul>
      <p>{`The current Canvas Web Library will be renamed to “Canvas Web v8” to indicate the version of Canvas
Kit that it reflects. A new version of the Canvas Web Figma library will be released called “Canvas
Web v9” to reflect the latest offers in Canvas Kit v9. No action is required for teams using Canvas
Kit v8. Teams that are upgrading to Canvas Kit v9 should also upgrade their design files to use the
Canvas Web v9 Figma library using the new Figma upgrade process.`}</p>
      <p>{`Upgrading to a new version (Canvas Web v9) will introduce breaking changes to affected components in
your files that are mentioned below The Canvas Design team will be available to support and advise
on how to accept these breaking changes. There will also be documentation available on release day
to help with this as well as a demo of these new features post release.`}</p>
      <p>{`This update is specific to the Canvas Figma libraries and will not impact developers. However,
developers should keep their designers updated on their upgrade timeline for Canvas v9 to ensure
design and development are using the same version of Canvas.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "depth-token-modifications"
    }}>{`Depth Token Modifications`}</h3>
    <p>{`Depth tokens will be updated to retract the changes made in Canvas v7, which introduced darker depth
values that resulted in a higher contrast against light colored backgrounds. The depth values will
not be reverted to pre-Canvas v7 levels, but they will be modified to reduce the visual impact of
depth.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2964858ffb78ff42a36940f735b0e184/e2e58/depth-comparison-v9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.21621621621621%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image displaying the six different depth values introduced in Canvas v7 and how they will change with the v9 release.",
            "title": "Image displaying the six different depth values introduced in Canvas v7 and how they will change with the v9 release.",
            "src": "/static/2964858ffb78ff42a36940f735b0e184/50383/depth-comparison-v9.png",
            "srcSet": ["/static/2964858ffb78ff42a36940f735b0e184/1efb2/depth-comparison-v9.png 370w", "/static/2964858ffb78ff42a36940f735b0e184/50383/depth-comparison-v9.png 740w", "/static/2964858ffb78ff42a36940f735b0e184/e2e58/depth-comparison-v9.png 1069w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Only the values of Canvas depth tokens will be modified as part of this update. Canvas components
will continue to use the default depth level they are currently tagged to for the time being.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`The depth changes will be applied automatically once designers update to the new Canvas Tokens Figma
Library. Changes made to customize the depth level of a component will still persist, but the value
of that depth level will be updated to reflect the v9 changes. Designers should review active design
files and modify the depth levels their designs are using accordingly. Designers using depth tokens
for components or designs created outside of Canvas should also accept the changes in the Canvas
Tokens Figma library.`}</p>
    </InternalContent>
    <p>{`This update will be applied automatically once developers upgrade to Canvas Kit v9.`}</p>
    <h3 {...{
      "id": "table-refactoring"
    }}>{`Table Refactoring`}</h3>
    <p>{`A new styled Table component will be available in the Preview package. This Table component will be
a more modernized and flexible version of the current Table component. The Canvas Table will contain
the basic features needed for Tables such as:`}</p>
    <ul>
      <li parentName="ul">{`Displaying data in a row/column format`}</li>
      <li parentName="ul">{`Differentiated row header`}</li>
      <li parentName="ul">{`Customizable column width`}</li>
      <li parentName="ul">{`Customizable horizontal and vertical borders`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`For more complex use cases with the need for built-in features and functionality, please refer to
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/pages/design/anthology/?path=/story/tables2.0_table--columns-with-custom-initial-width"
        }}>{`Tables 2.0`}</a></p>
    </InternalContent>
    <p>{`This update will not impact developers as it will be released as a net-new Table component. However,
it is recommended that teams switch over to this new Table component. It will be up to product teams
to add additional functionalities like tooltips, truncated text, etc. and to get them reviewed and
approved by accessibility.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`It will also not impact designers since Table will be introduced as a new component in the Canvas
Web v9 Figma library.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "new-combobox-system"
    }}>{`New Combobox System`}</h3>
    <p>{`A new Combobox sub-system will be created to help developers build components and features using
Combobox, like autocomplete and Select. This new Combobox will be released into the Main package.
Deprecation warnings will be added to the current Combobox component in Labs, but will not be
removed in this release. Other Canvas components that are currently using the Combobox in Labs, like
Search Form, will continue to do so in v9.`}</p>
    <p>{`This update will not impact developers. However, developers should start migrating to the new
Combobox component in Main once it is released in v9.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
      <h3 {...{
        "id": "modal-figma-updates"
      }}>{`Modal Figma Updates`}</h3>
      <p>{`To align with Canvas Kit, the following updates will be made to the Modal component in Figma as part
of the v9 release: The medium sized Modal variant will be removed as developers do not have pre-set
sizes for Modal in Canvas Kit and are able to adjust the size of the Modal manually With the removal
of the medium sized Modal, the small Modal variant will be renamed to “Web” to keep it distinct from
the responsive Modal variant that is only displayed on smaller screen sizes An overlay will be added
to the web Modal variant, which is always present when using the Modal in Canvas Kit`}</p>
      <p>{`Once designers migrate to the new Canvas Web v9 Figma library, they will need to: Switch out the
medium Modal variant with the newly renamed web Modal variant to maintain the connection to a
supported version of the Modal component in the Canvas Web v9 Figma library The Modal Component will
need to be resized so that the overlay covers the entire viewport, keeping the Modal align center,
center on screen. Switch out all instances of Modal that do not need an overlay with the new Dialog
component, which does not include an overlay and will allow users to interact with the rest of the
page`}</p>
      <p>{`This update is specific to the Canvas Web v9 Figma library and will not impact developers.`}</p>
      <h3 {...{
        "id": "new-figma-dialog-component"
      }}>{`New Figma Dialog Component`}</h3>
      <p>{`A new Dialog component will be added to the Canvas Web Figma library. This component is visually
similar to Modal, but will not contain an overlay and will allow users to interact with the rest of
the page.`}</p>
      <p>{`This update is specific to the Canvas Web Figma library and will not impact developers. The Dialog
component is already available in Canvas Kit for developers to use.`}</p>
      <p>{`It will also not impact designers as it will be a net new component in the Canvas Web Figma library.`}</p>
      <h3 {...{
        "id": "segmented-control-figma-updates"
      }}>{`Segmented Control Figma Updates`}</h3>
      <p>{`The Segmented Control component currently available in the Canvas Web Figma library will be replaced
with the redesigned Segmented Control component released in Canvas v8, which includes support for
both text and icons. Both Segmented Control components will still be available for use in Canvas
Kit. View the `}<a parentName="p" {...{
          "href": "#redesigned-segmented-control-component"
        }}>{`Canvas v8 announcement`}</a>{` for more information
on the redesigned Segmented Control component.`}</p>
      <p>{`This update will not impact developers as both versions of Segmented Control are currently available
in Canvas Kit today and there will not be any changes made to either component in v9. However, it is
recommended for teams to switch over to the redesigned Segmented Control component both in the
Preview package of Canvas Kit and the new Canvas Web v9 Figma library once it becomes available as
the outdated Segmented Control component will be deprecated and removed at a later date.`}</p>
      <p>{`The contents of Segmented Control in Figma will not persist when designers swap to the Canvas Web v9
Figma Library. It will default to the text only, horizontal variant with four segments. Designers
should reconfigure any overrides and variants to match previous designs if they choose to upgrade to
v9.`}</p>
      <h3 {...{
        "id": "status-indicator-figma-updates"
      }}>{`Status Indicator Figma Updates`}</h3>
      <p>{`The Status Indicator component currently available in the Canvas Web Figma library will be replaced
with the redesigned Status Indicator component released in Canvas v8, which includes globalization
and accessibility enhancements. Both Status Indicator components will still be available for use in
Canvas Kit. View the `}<a parentName="p" {...{
          "href": "#redesigned-status-indicator-component"
        }}>{`Canvas v8 announcement`}</a>{` for more
information on the redesigned Status Indicator component.`}</p>
      <p>{`This update will not impact developers as both versions of Status Indicator are currently available
in Canvas Kit today and there will not be any changes made to either component in v9. However, it is
recommended for teams to switch over to the redesigned Status Indicator component both in the
Preview package of Canvas Kit and the new Canvas Web v9 Figma library once it becomes available as
the outdated Status Indicator component will be deprecated and removed at a later date.`}</p>
      <p>{`Swapping to the Canvas Web v9 Figma library will automatically swap existing Status Indicators in a
design file with the new Status Indicator containing the updated visual style. This will not
override pre-set color, icons, or text. If a design file is using the old Status Indicators with an
icon, designers may need to perform an additional instance swap within their file to ensure pre-set
customizations persist.`}</p>
      <h3 {...{
        "id": "switch-refactoring-and-consolidation-figma-updates"
      }}>{`Switch Refactoring and Consolidation Figma Updates`}</h3>
      <p>{`The Switch component will be consolidated into a single component with variants (no label, top
label, left label) to standardize the way Canvas components are built across the Canvas Figma
libraries.`}</p>
      <p>{`The Switch will default to the No Label variant available when designers perform a library swap over
to the new Canvas Web v9 Figma library. Designers should reconfigure the variant to match previous
designs if they choose to upgrade to v9.`}</p>
      <p>{`This update is specific to the Canvas Web v9 Figma library and will not impact developers.`}</p>
      <h3 {...{
        "id": "radio-variant-consolidation"
      }}>{`Radio Variant Consolidation`}</h3>
      <p>{`The Radio component in Figma will be refactored to reduce a large number of redundant variants and
improve memory usage. There will be no change to existing functionality, but this update will
minimize future bugs and instances of breaking changes going forward.`}</p>
      <p>{`Swapping to the Canvas Web v9 Figma library will automatically swap to the updated Radio Buttons
with boolean and text props. Designers may need to perform an additional instance swap within their
file to preserve pre-set customizations.`}</p>
      <p>{`This update is specific to the Canvas Web v9 Figma library and will not impact developers.`}</p>
      <h3 {...{
        "id": "checkbox-variant-consolidation"
      }}>{`Checkbox Variant Consolidation`}</h3>
      <p>{`The Checkbox component in Figma will be refactored to reduce a large number of redundant variants
and improve memory usage. There will be no change to existing functionality, but this update will
minimize future bugs and instances of breaking changes going forward.`}</p>
      <p>{`Swapping to the Canvas Web v9 Figma library will automatically swap to the updated Checkboxes with
boolean and text props. Designers may need to perform an additional instance swap within their file
to preserve pre-set customizations.`}</p>
      <p>{`This update is specific to the Canvas Web v9 Figma library and will not impact developers.`}</p>
      <h3 {...{
        "id": "delete-button-figma-updates"
      }}>{`Delete Button Figma Updates`}</h3>
      <p>{`The Delete Button in the Canvas Web Figma library will be updated to standardize the component
structure across all Canvas buttons. This will allow the size, text, and state of the button to
persist when swapping between all Canvas button types. The following updates will be made:
Properties will be reordered and standardized New icon and state variants will be added to align
with other button types`}</p>
      <p>{`This will not impact existing designs.`}</p>
      <p>{`This update is specific to the Canvas Web Figma library and will not impact developers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "layout-component-removal"
    }}>{`Layout Component Removal`}</h3>
    <p>{`The Layout component will be removed from Canvas Kit to reduce maintenance costs and minimize the
confusion around the various types of Layout components. It will no longer be available for use in
Canvas v9.`}</p>
    <p>{`Developers using the Layout component should switch over to the Grid component, which will fulfill
the same functionality as Layout. The original Layout examples have been recreated using the Grid
component to help developers transition off of the Layout component.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs. Designers can continue
to use the Layout Grid component in the Canvas Web Figma library, which corresponds to the Grid
component in Canvas Kit.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "drawer-component-removal"
    }}>{`Drawer Component Removal`}</h3>
    <p>{`The Drawer component will be removed from Canvas Kit to reduce maintenance costs and minimize the
confusion around other similar components, like Side Panel. It will no longer be available for use
in Canvas v9.`}</p>
    <p>{`Developers using the Drawer component should switch over to the Side Panel component in the Preview
package, which will fulfill the same functionality as Drawer.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "stack-component-removals"
    }}>{`Stack Component Removals`}</h3>
    <p>{`The Stack, HStack and VStack components will be removed from Canvas Kit to reduce maintenance costs
and minimize the confusion around the various types of Layout components. These Stack components
will no longer be available to use in Canvas Kit v9.`}</p>
    <p>{`Developers should switch over to using Flex instead, which will have the same functionality as
Stack, HStack, and VStack. A codemod will be available to help developers migrate to Flex. However,
teams using StackProps for custom components will also need to switch out the spacing prop for the
gap prop in code.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "default-button-type-modifications"
    }}>{`Default Button Type Modifications`}</h3>
    <p>{`The default type attribute for Canvas buttons will be updated from `}<inlineCode parentName="p">{`type="submit"`}</inlineCode>{` to
`}<inlineCode parentName="p">{`type="button"`}</inlineCode>{` to prevent forms from accidentally being submitted when using buttons inside a form
element. This change will be applied to the following Canvas buttons:`}</p>
    <ul>
      <li parentName="ul">{`Primary Button`}</li>
      <li parentName="ul">{`Secondary Button`}</li>
      <li parentName="ul">{`Tertiary Button`}</li>
      <li parentName="ul">{`Delete Button`}</li>
      <li parentName="ul">{`Toolbar Button`}</li>
    </ul>
    <p>{`Developers that want to continue to use `}<inlineCode parentName="p">{`type="submit"`}</inlineCode>{` will need to manually make this adjustment.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "usetheme-updates"
    }}>{`useTheme Updates`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook will be updated to generate an error when used outside of a hook. This error has
been hidden in the past, which resulted in teams incorrectly using the `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook.`}</p>
    <p>{`Developers using the `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook in a regular function will need to switch it out with the
`}<inlineCode parentName="p">{`getTheme`}</inlineCode>{` instead.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "usethemedring-promotion"
    }}>{`useThemedRing Promotion`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useThemedRing`}</inlineCode>{` hook will be promoted from the Labs package to Main to prevent bi-directional
dependencies between the Main package and other packages like Labs and Preview.`}</p>
    <p>{`A codemod will be available to help developers handle the migration to the Main package.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "usethemertl-deprecation"
    }}>{`useThemeRTL Deprecation`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useThemeRTL`}</inlineCode>{` will be deprecated from Canvas Kit as it is no longer necessary with the
introduction of logical CSS properties. This hook will still be available for use in Canvas v9, but
deprecation warnings will appear when it is in use.`}</p>
    <p>{`Developers should start replacing this hook with logical CSS properties as it will be removed from
Canvas Kit in the near future.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "usecanvastheme-and-getcanvastheme-removal"
    }}>{`useCanvasTheme and getCanvasTheme Removal`}</h3>
    <p>{`The utility functions `}<inlineCode parentName="p">{`useCanvasTheme`}</inlineCode>{` and `}<inlineCode parentName="p">{`getCanvasTheme`}</inlineCode>{` will be removed in favor of `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{`
and `}<inlineCode parentName="p">{`getTheme`}</inlineCode>{`, respectively. These functions were only intended for internal use and were
predecessors of `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` and `}<inlineCode parentName="p">{`getTheme`}</inlineCode>{`. They are being removed to get rid of unnecessary
duplicates.`}</p>
    <p>{`Developers using these functions will need to update the name of the function.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "canvas-kits-typescript-compiler-target-updates"
    }}>{`Canvas Kit’s TypeScript Compiler Target Updates`}</h3>
    <p>{`The TypeScript compiler target for Canvas Kit will be updated from ES5 to ES2019. All modern
browsers support ES2019, so this update will significantly reduce the Canvas Kit bundle size.`}</p>
    <p>{`This will not impact designers or developers. However, developers should still confirm that tests
are still passing and that their application is still compiling properly.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "toast-component-promotion"
    }}>{`Toast Component Promotion`}</h3>
    <p>{`The Toast compound component in the Labs package will be promoted to the Main package to replace the
current Toast component in Main and reduce the number of duplicative components in Canvas Kit.`}</p>
    <p>{`A codemod will be available to help developers handle the migration to the Main package.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "enhanced-qa--testing-processes"
    }}>{`Enhanced QA & Testing Processes`}</h3>
    <p>{`The Canvas QA and testing processes will be enhanced and utilized in the Canvas v9 release. This
will include:`}</p>
    <ul>
      <li parentName="ul">{`Adding visual test for different container widths to Action Bar and Breadcrumbs`}</li>
      <li parentName="ul">{`Adding all Cypress tests to Action Bar`}</li>
      <li parentName="ul">{`Adding Cypress tests for different container widths to Breadcrumbs`}</li>
      <li parentName="ul">{`Adding Cypress tests for touch modality to Tabs and Modal`}</li>
      <li parentName="ul">{`Adding visual tests for mobile screen to Tabs and Modal`}</li>
      <li parentName="ul">{`Adding visual tests for the overflow Tabs variant`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`In addition to enhancing code testing, the design QA process will also be revamped to include
directly testing major design changes and regressions with Workday product teams. The updated QA
process will be applied to the depth token updates prior to pushing out the change in this release.
This will be followed by enhanced codemod testing with additional Workday product teams.`}</p>
      <p>{`This update will not impact designers or developers.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`This update will not impact developers.`}</p>
    </ExternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      